import axios from '@axios'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchUsers(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get((process.env.VUE_APP_BACKEND || '') + '/api/liveness/', { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchStatuses(ctx) {
			return new Promise((resolve, reject) => {
				axios
					.get((process.env.VUE_APP_BACKEND || '') + '/api/livenessStatuses/')
					.then(response => {
						resolve(response)
					})
			})
			
		},
	},
}
