<template>
	<div style="position: relative">
		<query-list-settings
			:is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
			:plan-options="planOptions"
		/>
		<b-avatar
			rounded
			size="36"
			variant="primary"
			style="color: white; position: absolute; top: -50px; right: 50px; z-index: 99; cursor: pointer"
		>
			<feather-icon
				:icon="isToggleFiltersActive ? 'ChevronUpIcon' : 'ChevronDownIcon'"
				size="25"
				class="text-secondary"
				badge-classes="bg-primary"
				@click="isToggleFiltersActive = !isToggleFiltersActive"
				style="color: white !important"
			/>
		</b-avatar>
		<div v-if="isToggleFiltersActive">
			<date-tabs />
			<b-card no-body>
				<b-card-header class="pb-50">
					<h5>Фильтры</h5>
					<b-col cols="12" md="6">
						<div class="d-flex align-items-center justify-content-end">
							<!-- <b-button variant="outline-primary" @click="isAddNewUserSidebarActive = true">
							<span class="text-nowrap">Выбрать столбцы</span>
						</b-button> -->
						</div>
					</b-col>
				</b-card-header>
				<b-card-body>
					<query-list-filters
						:role-filter.sync="roleFilter"
						:plan-filter.sync="planFilter"
						:passport-filter.sync="passportFilter"
						:status-filter.sync="statusFilter"
						:request-type-filter.sync="requestTypeFilter"
						:plan-options="planOptions"
						:status-options="statusOptions"
						:request-options="requestOptions"
						:refetchData="refetchData"
					/>
				</b-card-body>
			</b-card>
		</div>

		<!-- Table Container Card -->
		<b-card no-body class="mb-0">
			<b-table
				ref="refUserListTable"
				sticky-header="60vh"
				class="position-relative"
				:items="fetchUsers"
				responsive
				:fields="tableColumns"
				primary-key="id"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="Результатов не найдено"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(camera_img_url)="data" class="px-1">
					<b-media vertical-align="center">
						<template>
							<b-link
								v-if="data.item.camera_img"
								:href="
									(data.item.camera_img.minio_path || '').replace(
										'http://minio:9000',
										VUE_APP_MINIO || '',
									)
								"
								target="_blank"
							>
								<b-avatar
									size="32"
									:src="
										(
											data.item.camera_img.cropped_minio_path ||
											data.item.camera_img.minio_path ||
											''
										).replace('http://minio:9000', VUE_APP_MINIO || '')
									"
									:variant="`light-primary`"
								/>
							</b-link>
							<b-link
								v-if="data.item.id_img"
								:href="
									(data.item.id_img.minio_path || '').replace(
										'http://minio:9000',
										VUE_APP_MINIO || '',
									)
								"
								target="_blank"
							>
								<b-avatar
									size="32"
									:src="
										(
											data.item.id_img.cropped_minio_path ||
											data.item.id_img.minio_path ||
											''
										).replace('http://minio:9000', VUE_APP_MINIO || '')
									"
									:variant="`light-primary`"
								/>
							</b-link>
						</template>
					</b-media>
				</template>
				<!-- Column: Status -->
				<template #cell(id)="data">
					<b-badge
						pill
						:variant="`light-${resolveUserStatusVariant(data.item.status)}`"
						class="text-capitalize"
					>
						{{ data.item.id }}
					</b-badge>
					<b-badge
						pill
						class="text-capitalize"
						style="cursor: pointer; margin-top: 0.5em"
						@click="
							requestTypeFilter = ''
							requestTypeFilter = data.item.request_type
							window.scrollTop = 0
							refetchData()
						"
					>
						{{
							{
								liveness_only: 'Liveness',
								liveness_comparison: 'Combo',
								comparison_only: 'Comparison',
							}[data.item.request_type]
						}}
					</b-badge>
				</template>
				<!-- Column: Status -->

				<template #cell(exec_time_ms)="data">
					{{ (data.item.exec_time_ms / 1000).toFixed(2) + 'с' }}
				</template>

				<template v-slot:head()="data">
					<span v-b-tooltip.hover.top="data.field.label" :data-icon="data.field.key">{{
						data.field.label
					}}</span>
				</template>

				<template #cell(created)="data">
					{{ new Date(data.item.created).toLocaleString() }}
				</template>

				<template #cell(person_id)="data">
					<b-badge
						pill
						style="cursor: pointer"
						@click="
							passportFilter = ''
							passportFilter = data.item.person_id
							window.scrollTop = 0
							refetchData()
						"
					>
						{{ data.item.person_id }}
					</b-badge>
				</template>

				<template #cell(hasFace)="data">
					<span class="badge-center"
						><b-badge
							pill
							:variant="`light-${resolveUserStatusVariant(resolveLiveness('hasFace', data.item))}`"
						>
							{{ resolveLiveness('hasFace', data.item) }}
						</b-badge></span
					>
				</template>

				<template #cell(eyesOpen)="data">
					<span class="badge-center"
						><b-badge
							pill
							:variant="`light-${resolveUserStatusVariant(resolveLiveness('eyesOpen', data.item))}`"
						>
							{{ resolveLiveness('eyesOpen', data.item) }}
						</b-badge></span
					>
				</template>

				<template #cell(blurriness)="data">
					<span class="badge-center"
						><b-badge
							pill
							:variant="`light-${resolveUserStatusVariant(resolveLiveness('blurriness', data.item))}`"
						>
							{{ resolveLiveness('blurriness', data.item) }}
						</b-badge></span
					>
				</template>

				<template #cell(isColourful)="data">
					<span class="badge-center"
						><b-badge
							pill
							:variant="`light-${resolveUserStatusVariant(resolveLiveness('isColourful', data.item))}`"
						>
							{{ resolveLiveness('isColourful', data.item) }}
						</b-badge></span
					>
				</template>

				<template #cell(faceAlignment)="data">
					<span class="badge-center"
						><b-badge
							pill
							:variant="`light-${resolveUserStatusVariant(resolveLiveness('faceAlignment', data.item))}`"
						>
							{{ resolveLiveness('faceAlignment', data.item) }}
						</b-badge></span
					>
				</template>

				<template #cell(recognizedFaces)="data">
					<span class="badge-center"
						><b-badge
							pill
							:variant="`light-${resolveUserStatusVariant(
								resolveLiveness('recognizedFaces', data.item),
							)}`"
						>
							{{ resolveLiveness('recognizedFaces', data.item) }}
						</b-badge></span
					>
				</template>

				<template #cell(is_recognized)="data">
					<span class="badge-center"
						><b-badge
							pill
							:variant="`light-${resolveUserStatusVariant(resolveLiveness('is_recognized', data.item))}`"
						>
							{{ resolveLiveness('is_recognized', data.item) }}
						</b-badge></span
					>
				</template>

				<template #cell(liveness_status)="data">
					<span class="badge-center"
						><b-badge
							pill
							:variant="`light-${resolveUserStatusVariant(
								data.item.preprocessing_response ? data.item.preprocessing_response.is_alive : null,
							)}`"
						>
							{{ '' + (data.item.preprocessing_response ? resolveLiveness('is_alive', data.item) : '') }}
						</b-badge></span
					>
				</template>

				<template #cell(combo_status)="data">
					<span
						class="badge-center"
						:_="
							(combo = (() => {
								if (!data.item.preprocessing_response) return null
								return (
									data.item.preprocessing_response.is_alive &&
									data.item.preprocessing_response.is_recognized
								)
							})())
						"
						><b-badge pill :variant="`light-${resolveUserStatusVariant(combo)}`">
							{{ '' + (combo === null ? '' : combo) }}
						</b-badge></span
					>
				</template>

				<template #cell(error)="data">
					{{ data.item.preprocessing_response ? data.item.preprocessing_response.message : '' }}
				</template>
			</b-table>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted"
							>Показаны с {{ dataMeta.from }} до {{ dataMeta.to }} из {{ dataMeta.of }} результатов</span
						>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalUsers"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
	import {
		BCard,
		BCardBody,
		BCardHeader,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		VBTooltip,
	} from 'bootstrap-vue'
	import vSelect from 'vue-select'
	import store from '@/store'
	import { ref, onUnmounted, onMounted } from '@vue/composition-api'
	import { avatarText } from '@core/utils/filter'
	import QueryListFilters from './QueryListFilters.vue'
	import useQueryList from './useQueryList'
	import queryStoreModule from '../queryStoreModule'
	import QueryListSettings from './QueryListSettings.vue'
	import DateTabs from '@/views/statistics/DateTabs.vue'
	import { useRouter } from '@/@core/utils/utils'

	export default {
		components: {
			QueryListFilters,
			QueryListSettings,
			DateTabs,
			BCard,
			BCardBody,
			BCardHeader,
			BRow,
			BCol,
			BFormInput,
			BButton,
			BTable,
			BMedia,
			BAvatar,
			BLink,
			BBadge,
			BDropdown,
			BDropdownItem,
			BPagination,

			vSelect,
			VBTooltip,
		},
		directives: {
			'b-tooltip': VBTooltip,
		},
		setup(a, b) {
			console.log(a, b)
			const USER_APP_STORE_MODULE_NAME = 'app-query'
			const isToggleFiltersActive = false
			// Register module
			if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
				store.registerModule(USER_APP_STORE_MODULE_NAME, queryStoreModule)

			// UnRegister on leave
			onUnmounted(() => {
				if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
			})

			const route = useRouter()

			onMounted(() => {
				if (route?.route?.value?.params?.id) {
					roleFilter.value = route.route.value.params.id
					statusFilter.value = 'nok'
				}
			})

			const isAddNewUserSidebarActive = ref(false)

			const planOptions = [
				{ label: 'OK', value: 'ok' },
				{ label: 'Not OK', value: 'nok' },
				{ label: 'Null', value: 'null' },
				{ label: 'Not Null', value: 'notNull' },
			]

			const statusOptions = [
				{ label: 'успешно', value: 'ok' },
				{ label: 'неуспешно', value: 'nok' },
				{ label: 'Null', value: 'null' },
				{ label: 'Not Null', value: 'notNull' },
			]

			const requestOptions = [
				{ label: 'Liveness', value: 'liveness_only' },
				{ label: 'Comparison', value: 'comparison_only' },
				{ label: 'Combo', value: 'liveness_comparison' },
			]

			const {
				fetchUsers,
				tableColumns,
				perPage,
				currentPage,
				totalUsers,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refUserListTable,
				refetchData,

				// UI
				resolveUserRoleVariant,
				resolveUserRoleIcon,
				resolveUserStatusVariant,
				resolveLiveness,

				statuses,
				// Extra Filters
				roleFilter,
				planFilter,
				statusFilter,
				passportFilter,
				requestTypeFilter,
			} = useQueryList()

			return {
				// Sidebar
				isAddNewUserSidebarActive,
				isToggleFiltersActive,
				fetchUsers,
				tableColumns,
				perPage,
				currentPage,
				totalUsers,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refUserListTable,
				refetchData,

				// Filter
				avatarText,

				// UI
				resolveUserRoleVariant,
				resolveUserRoleIcon,
				resolveUserStatusVariant,
				resolveLiveness,
				statuses,

				planOptions,
				statusOptions,
				requestOptions,

				// Extra Filters
				roleFilter,
				planFilter,
				statusFilter,
				passportFilter,
				requestTypeFilter,
				window,
				VUE_APP_MINIO: process.env.VUE_APP_MINIO,
			}
		},
	}
</script>

<style lang="scss">
.per-page-selector {
	width: 90px;
}
.table.b-table > thead > tr > [aria-sort],
.table.b-table > tfoot > tr > [aria-sort] {
	background-position-y: 1em !important;
	padding-right: 2em !important;
}
.table-b-table-default {
	padding-top: 2.3em !important;
	padding-bottom: 2.3em !important;
}
.table.b-table .badge-center {
	width: 100%;
	display: block;
	text-align: center;
}
.table .media-body {
	white-space: nowrap;
}
.table .media-body a + a {
	margin-left: 0.5em;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

span[data-icon] {
	display: block !important;
	width: 100%;
	text-align: center;
	opacity: 0.7;
	font-size: 0.6em;
	hyphens: auto;
	margin: 0 auto;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	top: 50%;
	transform: translateY(-50%);
}
span[data-icon]:before {
	content: '';
	display: block;
	width: 100%;
	height: 2.7em;
	margin-bottom: 1em;
	background-size: contain;
	background-position: 50% 0;
	background-repeat: no-repeat;
}
span[data-icon='id']:before {
	background-image: url(~@/assets/images/icons/material/id.svg);
}
span[data-icon='exec_time_ms']:before {
	background-image: url(~@/assets/images/icons/material/exec_time_ms.svg);
}
span[data-icon='person_id']:before {
	background-image: url(~@/assets/images/icons/material/person_id.svg);
}
span[data-icon='blurriness']:before {
	background-image: url(~@/assets/images/icons/material/blurriness.svg);
}
span[data-icon='created']:before {
	background-image: url(~@/assets/images/icons/material/created.svg);
}
span[data-icon='error']:before {
	background-image: url(~@/assets/images/icons/material/error.svg);
	background-position: 0 0;
}
span[data-icon='hasFace']:before {
	background-image: url(~@/assets/images/icons/material/hasFace.svg);
}
span[data-icon='eyesOpen']:before {
	background-image: url(~@/assets/images/icons/material/eyesOpen.svg);
}
span[data-icon='faceAlignment']:before {
	background-image: url(~@/assets/images/icons/material/faceAlignment.svg);
}
span[data-icon='isColourful']:before {
	background-image: url(~@/assets/images/icons/material/isColourful.svg);
}
span[data-icon='liveness_status']:before {
	background-image: url(~@/assets/images/icons/material/liveness_status.svg);
}
span[data-icon='recognizedFaces']:before {
	background-image: url(~@/assets/images/icons/material/recognizedFaces.svg);
}
span[data-icon='is_recognized']:before {
	background-image: url(~@/assets/images/icons/material/is_recognized.svg);
}

span[data-icon='error'] {
	text-align: left;
	width: auto;
	left: 3.5em;
}
</style>
